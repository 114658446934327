import React from 'react'
import classes from "./Spinner.module.css"

function Spinner() {
  return (
    <div className={classes.dot}>
      
    </div>
  )
}

export default Spinner