import React, { useEffect, useState } from 'react'
import { useGetRequestQuery, usePostRequestMutation } from '../../store/services/users';
import ProductList from '../ProductList/ProductList';
import Wrapper from '../UI/Wrapper/Wrapper'
import classes from "../../Styles/Home.module.css";
import Spinner from '../UI/Spinner/Spinner';
import NotFound from '../UI/NotFound/NotFound';

function Search() {

   
    let url = new URLSearchParams(window.location.search)
    let query: string  =  url.get("query") as unknown as string
  
    const {
      data = [],
      isLoading,
    } = useGetRequestQuery(`/search-product?limit=${9000000}&&query=${query}`);
  
  


    

  return (
    <Wrapper>
      <div>
      <div className={classes.product_wrapper}>
          {data.map((item: any) => {
            return (
              <ProductList
                image={item.image1}
                name={item.name}
                price={item.price}
                key={item.id}
                _id={item._id}
                item={item}
              />
            );
          })}
        </div>

        {isLoading && <Spinner />}

        {data.length < 1 && isLoading === false ? <NotFound /> : null}

      </div>
    </Wrapper>
  )
}

export default Search